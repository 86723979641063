.download_box {
    height: 150px,
}

.logo_links_box {
    height: 102vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.download {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: gray;
}

.slogan {
    min-height: 200px;
    vertical-align: center;
}

.download_button {
    margin-right: 10px;
    padding: 2px;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    transition: all 1s ease-out;
}

.download_button:hover {
    color: gray;
    border: 1px solid gray;
    cursor: pointer;
}

.bannerText {
    font-size: 50px;
}

.anchor {
    display: block;
    height: 60px;
    margin-top: -60px;
    visibility: hidden;
}

.anchor_contact {
    display: block;
    height: 20px;
    margin-top: -20px;
    visibility: hidden;
}

.contact-info {
    margin: 10px;
    display: flex;
    height: 400px;
    width: 280px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
}

.info_text {
    font-size: 20px;
    color: rgb(68, 68, 68);
    text-align: start;
}

.line {
    width: 280px;
    height: 4px;
    background-color: black;
    border-radius: 2px;
}

.formContainer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    width: 100%;
}

.form {
    width: 300px;
    height: 500px;
    color: black;
    background-color: white;
    border-radius: 5px;
    margin-right: 45px;
    margin-top: 40px;
    text-align: center;
    padding: 10px;
}

@media screen and (max-width: 870px) {
    .form {
        margin-right: 0px;
        margin-top: 30px;
    }

    .formContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}

.footer-container {
    background-color: white;
    height: 100px;
    width: 100vw;
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

.foot_download_button {
    margin-right: 10px;
    color: black;
}

.foot_download_button:hover {
    color: gray;
    cursor: pointer;
}

.footerLink {
    margin-right: 10px;
    color: black;
}

.footerLink:hover {
    text-decoration: underline;
}

.bar {
    height: 15px;
    width: 1px;
    background-color: black;
    margin-right: 10px;
}

.footer-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.routeLink {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.routeLink:hover {
    cursor: pointer;
}

.featureContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    width: 100%;
}

.f1InfoBox {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 30px 0px;
}

.f1InfoTab {
    height: 80px;
    width: 200px;
    color: black;
    background-color: rgb(185, 227, 247);
    font-size: 30px;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.f1InfoText {
    font-size: 30px;
}

.f2Header {
    width: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 25px 0px;
}

.f2Box {
    width: 260px;
    height: 350px;
    padding: 20px 40px;
    margin: 20px;
    background-color: rgba(114, 114, 114, 0.4);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.f2InfoHead {
    font-size: 30px;
    letter-spacing: 1px;
    margin-bottom: 50px;
}

.f2InfoText {
    font-size: 20px;
}

.f4InfoHead {
    margin: 25px 0px;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 2px;
    color: Black;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
}

.f4Nums {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 550px;
    color: black;
    justify-content: center;
}

.f4InfoNumBox {
    max-width: 200px;
    margin: 20px;
    background-color: rgba(192, 227, 241, 0.4);
    border-radius: 5px;
    padding: 15px;
}

.f4InfoPoint {
    font-size: 50px;
    font-weight: bold;
}

.f4InfoText {
    font-size: 25px;
}