li {
    margin-left: 20px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 10px;
}

.tpinner {
    padding: 0px 40px;
}